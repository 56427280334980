import React, { Component } from 'react';

class Impressum  extends Component {
    state = {  }
    render() { 
        return ( 
            <h1
            style={{textAlign: 'center',
            marginTop: 50,
            marginBottom: 50
            }}>
                Impressum</h1>
         );
    }
}
 
export default Impressum;