import logo from './logo.svg';
import './App.css';
import AppRedirect from './appRedirect'



function App() {

  let number = 0
  
    return (
      <AppRedirect></AppRedirect>
    
  )
}

export default App;
