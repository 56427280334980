import React, { Component } from 'react';

class NavBar extends Component {
    state = {  }

    

    render() { 

        return (  
            <div className="container">
    <header className="d-flex justify-content-center py-3">
      <ul className="nav nav-pills">
        <li className="nav-item"><button className={this.props.clicked[0] ? 'nav-link active': 'nav-link'}  onClick={
            () => this.props.onNumberClicked(1)
            }>Home</button></li>
        <li className="nav-item"><button className={this.props.clicked[1] ? 'nav-link active': 'nav-link'} onClick={
             () => this.props.onNumberClicked(2)
             }>Swift Scheduler</button></li>
        <li className="nav-item"><button className={this.props.clicked[2] ? 'nav-link active': 'nav-link'} onClick={
             () => this.props.onNumberClicked(3)}>One Tower</button></li>
        <li className="nav-item"><button className={this.props.clicked[3] ? 'nav-link active': 'nav-link'} onClick={
             () => this.props.onNumberClicked(4)}>Impressum</button></li>
        <li className="nav-item"><button className={this.props.clicked[4] ? 'nav-link active': 'nav-link'} onClick={
             () => this.props.onNumberClicked(5)}>About</button></li>
      </ul>
    </header>
  </div>
        );
    }
}
 
export default NavBar ;