import React, { Component, useState} from 'react';
import Calendar from 'react-calendar';
import axios from 'axios'
import Moment from 'react-moment';
import moment from 'moment';
import './create.css'
import Test from './test'

const Create = (props) => {

   
    const [calendar, onChangeCalendar] = useState('');
    const [group, onChangeGroupName] = React.useState('');
    const [password, onChangeGroupPassword] = React.useState('');
    const [textInput, onChangeTextInput] = React.useState('');
    const [textCalendar, onChangeTextCalendar] = React.useState('');
    const [selected, setSelected] = React.useState(true);
    const [selected2, setSelected2] = React.useState(false);
    const [range, setRange] = React.useState(true);
    const [classNameX, setClassNameX] = React.useState('content');
    const [mark, setMark] = React.useState([]);
    const [returnn, setReturnn] = React.useState('range');
    let rangeChanger = false
    let dataZ = []
    const textyy = " Select Range "
    const textyy2 = " Select Date "

    function onChangeCalenderFun (x){
        onChangeCalendar(x)
    }
    function setGroupName (data){
        onChangeGroupName(data.target.value)
    }
    function setGroupPassword (data){
        onChangeGroupPassword(data.target.value)
    }
    function changeSelected (data){
        setSelected(data)
        setSelected2(!data)
        setRange(true)
        if(returnn == 'range'){
        setReturnn('start')
        }else {
            setReturnn('range')
        }
    }
    function changeSelected2 (data){
        setSelected2(data)
        setSelected(!data)
        setRange(false)
        if(returnn == 'range'){
            setReturnn('start')
            }else {
                setReturnn('range')
            }
    }
    function onClickDayFun (date){
        if (returnn == 'range' && calendar.length == 2){
            for (var a = calendar[0].getFullYear(); a <= calendar[1].getFullYear(); a++){
                for (var b = (calendar[0].getMonth() + 1); b <= (calendar[1].getMonth() + 1); b++){
                    for (var c = calendar[0].getDate(); c <= calendar[1].getDate(); c++){
                        const place = mark
                        let z = '' 
                        if (b < 10 && c < 10) z = (a + "0" + b + "0" + c)
                        else if (b < 10) z =(a + "0" + b + c)
                        else if (c < 10) z =(a + b + "0" + c)
                        else z =(a + b + c)
                        if(mark.find(y => y == z)){
                            mark.forEach(y => {
                            if(z == y ){
                            const zz = mark.indexOf(y)
                            const place = mark
                            place.splice(zz, 1)
                            setMark(() => place)
                            }
                            })
                            }else {
                            const place = mark
                            place.push(z)
                            setMark(() => place)
                            }
                            
                    }
                }
            }
            onChangeCalenderFun(date)
        }
        else if (returnn == 'start') {
        const x = moment(date).format("YYYYMMDD")
        if(mark.find(y => y == x)){
        mark.forEach(y => {
        if(x == y){
        const z = mark.indexOf(y)
        const place = mark
        place.splice(z, 1)
        setMark(() => place)
        }
        })
        }else {
        const place = mark
        place.push(x)
        setMark(() => place)
        }
    }
    }

    new Date(2017, 0, 1)

    const onCreateGroup = () => {
        
        if (group == '' || password == '') {
            onChangeTextInput('Please enter a valid group name/group password')
        }else if(mark.length < 2) {
                onChangeTextCalendar('Please select at least 2 dates')

        } else {
            console.log('Group created')
        axios({
            method: 'POST',
            url: 'https://www.ag-solutions-group.com/create_group.php?',
            data: {groupp: group, password: password}
        })
        .then(result => {
            console.log(result)
            if (result.status == "false"){
                console.log('false!!!')
            }
            mark.forEach (x => {
                dataZ.push({groupp: group, name:'admin', date: x})
            });
            dataZ.forEach (y => {
                axios({
                    method: 'POST',
                    url: 'https://www.ag-solutions-group.com/swift_date_input.php?',
                    data: y
                })
                .then(result => {
                    console.log(result.data)
                    props.joinClick([0, ''])
                    })
                .catch(error => {
                    if (error == "Group already exists!") {
                        onChangeTextInput('Group already exists')
                    } 
                        console.log(error.data)
                   })
                })
        })  
        .catch(error => {
            if (error == "Group already exists!") {
                onChangeTextInput('Group already exists')
            } 
                console.log(error.data)
           })
        }
        
    }

    return (  
        <div>
            
            <div>
            <input
            value={group}
            onChange={setGroupName}
            placeholder="group name"></input>
            
            </div>
            <div>
            <input
            value={password}
            onChange={setGroupPassword}
            placeholder="group password"></input>
            <h5 style={{color: 'red'}}>{textInput}</h5>
            </div>

        <div>
        <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
        <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>
        </div>
            <div style={{marginTop: 20, marginBottom: 10}}>
            <input type="checkbox" onChange={data => changeSelected(data.target.checked)} checked={selected}></input>
            {textyy}
            <input type="checkbox" onChange={data => changeSelected2(data.target.checked)} checked={selected2}></input>
            {textyy2}
            </div>
            <Calendar
                onChange={(x) => onChangeCalenderFun(x)}
                value={calendar}
                defaultView = {"month"}
                minDate = {new Date()}
                selectRange= {range}
                returnValue= {returnn}
                onClickDay= {date => {
                    onClickDayFun(date)
                }}
                tileClassName = {({date}) => {
                    if (mark.find(x => x == moment(date).format("YYYYMMDD"))){
                        return 'react-calendar__tile--active'
                        } else {
                            return 'react-calendar__tile'
                    }
                }
                }
                >
                 </Calendar>
                 <Test test={returnn}></Test>

                 <h5 style={{color: 'red'}}>{textCalendar}</h5>
            <div>
                 <Moment format="DD.MM.YYYY">{calendar}</Moment>
                 </div>
            </div >
             <div
             style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 50,
            marginBottom: 50
                }}>
            <button style={{margin: 5}} className="btn btn-primary btn-lg"
            onClick= { () => props.joinClick(0)}
            >
                Back
            </button>
            <button style={{margin: 5}} className="btn btn-primary btn-lg"
            onClick= {onCreateGroup}
            >
                Create Group
            </button>
            </div>
        </div>
    );
}



export default Create;

 