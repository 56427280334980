import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import PickPage from './pickPage'
import NavBar from './navBar'
import { render } from '@testing-library/react';

class AppRedirect extends Component {
    state = { 
        number: 1,
        clicked: [true, false, false, false, false]
    }

    numberClicked = (numberX) => {
        const numberY = numberX
        const clickedPlace = [false, false, false, false, false]
        clickedPlace[numberX -1] = true
            this.setState({number: numberY, clicked: clickedPlace})
    }

    render(
    ) { 
        return (  
            <div>
    <NavBar onNumberClicked ={this.numberClicked} clicked ={this.state.clicked}></NavBar>
    <div >
      <PickPage number={this.state.number}></PickPage>
    </div>
    </div>
        );
    }
}
 
export default AppRedirect;