import React, { Component, Fragment, useState } from 'react';
import CalendarX from 'react-calendar'
import axios from 'axios'
import './calendar.css'
import Moment from 'react-moment';
import moment from 'moment';
import PickPage from './pickPage';
import { render } from '@testing-library/react';


const Calendar = (props)  =>  {
    
    const [calendar, onChangeCalendar] = useState('');
    const [mark, setMark] = React.useState([]);
    const [mark2, setMark2] = React.useState(['']);
    const [returnn, setReturnn] = React.useState('range');
    const [range, setRange] = React.useState(true);
    const [selected, setSelected] = React.useState(true);
    const [selected2, setSelected2] = React.useState(false);
    const [minDateZ, setminDateZ] = React.useState('');
    const [maxDateZ, setmaxDateZ] = React.useState('');
    const textyy = " Select Range ";
    const textyy2 = " Select Date ";
    const [getDataPlace, setGetDataPlace] = React.useState(true)

    function changeSelected (data){
        setSelected(data)
        setSelected2(!data)
        setRange(true)
        if(returnn == 'range'){
        setReturnn('start')
        }else {
            setReturnn('range')
        }
    }
    function changeSelected2 (data){
        setSelected2(data)
        setSelected(!data)
        setRange(false)
        if(returnn == 'range'){
            setReturnn('start')
            }else {
                setReturnn('range')
            }
    }

        function onChangeCalenderFun (x){
            onChangeCalendar(x)
            if (getDataPlace){
                getDataPlace = false
                axios.get('https://www.ag-solutions-group.com/swift_get_date_input.php?',
                { params: {groupp: props.groupname}})
            .then(result => {
            const place = Object.values(result.data);
            const place2 = [];
            place.forEach(element => {
             place2.push(Object.values(element))
        }
        );
            setMark2(() => place2);
            setminDateZ(Math.min(place2));
            setmaxDateZ(Math.max(place2));
            })
            .catch(error => {
           console.log(error)
            })
        }
        }

        function putData() {
           
        
        }

        function onClickDayFun (date){
            if (returnn == 'range' && calendar.length == 2){
                for (var a = calendar[0].getFullYear(); a <= calendar[1].getFullYear(); a++){
                    for (var b = (calendar[0].getMonth() + 1); b <= (calendar[1].getMonth() + 1); b++){
                        for (var c = calendar[0].getDate(); c <= calendar[1].getDate(); c++){
                            const place = mark
                            let z = '' 
                            if (b < 10 && c < 10) z = (a + "0" + b + "0" + c)
                            else if (b < 10) z =(a + "0" + b + c)
                            else if (c < 10) z =(a + b + "0" + c)
                            else z =(a + b + c)
                            if(mark.find(y => y == z)){
                                mark.forEach(y => {
                                if(z == y ){
                                const zz = mark.indexOf(y)
                                const place = mark
                                place.splice(zz, 1)
                                setMark(() => place)
                                }
                                })
                                }else {
                                const place = mark
                                place.push(z)
                                setMark(() => place)
                                }
                                
                        }
                    }
                }
                onChangeCalenderFun(date)
            }
            else if (returnn == 'start') {
            const x = moment(date).format("YYYYMMDD")
            if(mark.find(y => y == x)){
            mark.forEach(y => {
            if(x == y){
            const z = mark.indexOf(y)
            const place = mark
            place.splice(z, 1)
            setMark(() => place)
            }
            })
            }else {
            const place = mark
            place.push(x)
            setMark(() => place)
            }
        }
        }

        return (
            <div
             >
                <div></div>
                <div style={{marginTop: 20, marginBottom: 10}}>
            <input type="checkbox" onChange={data => changeSelected(data.target.checked)} checked={selected}></input>
            {textyy}
            <input type="checkbox" onChange={data => changeSelected2(data.target.checked)} checked={selected2}></input>
            {textyy2}
            </div>
                <div>
                <CalendarX
                onChange={(x) => onChangeCalenderFun(x)}
                value={calendar}
                defaultView = {"month"}
                minDate = {minDateZ, console.log(minDateZ)}
                maxDate = {maxDateZ, console.log(maxDateZ)}
                selectRange= {range}
                returnValue= {returnn}
                onClickDay= {date => {
                    onClickDayFun(date)
                }}
                tileClassName = {({date}) => {
                    console.log(getDataPlace)
                    if (getDataPlace){
                        setGetDataPlace(() => false)
                        axios.get('https://www.ag-solutions-group.com/swift_get_date_input.php?',
                        { params: {groupp: props.groupname}})
                    .then(result => {
                    const place = Object.values(result.data);
                    let place4 = []
                    let place5 = []
                    place.forEach(x => {
                            place4.push(Object.values(x))
                    })
                    place4.forEach(y => {
                        const a = y[0]
                        place5.push(a)
                    })
                    console.log(place)
                    console.log(place4)
                    console.log(place5)
                    setMark2(() => place5);
                    setminDateZ(Math.min(place5));
                    setmaxDateZ(Math.max(place5));
                    })
                    .catch(error => {
                   console.log(error)
                    })
                }

                        console.log(mark)
                        console.log(mark2)
                        if (mark2.find(x => x == moment(date).format("YYYYMMDD"))){
                            return '.react-calendar__tile:disabled'
                        } else if (mark != undefined && mark.find(x => x == moment(date).format("YYYYMMDD"))){
                            return '.react-calendar__tile--active'
                            } else {
                                return '.react-calendar__tile'
                }
                }
                }
                >
                 </CalendarX>
                 </div >
                 <div
                 style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 50,
                marginBottom: 50
                    }}>
                <button className="btn btn-primary btn-lg"
                onClick= { () => props.joinClick([0, ''])}
                >
                    Back
                </button>
                </div>
            </div>
          );
}
 
export default Calendar;