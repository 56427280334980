import React, { Component } from 'react';
import Calendar from './calendar'
import Landing from './landing'
import Home from './home'
import About from './about'
import Impressum from './impressum'
import OneTower from './oneTower'
import Create from './create'

class PickPage extends Component {
    state = {  
        startingPage: 0,
        groupName: 'x'
    }

    handleJoinClick = (x) => {
      const startingPagePlace= x[0]
      const groupnameplace = x[1]
      this.setState({startingPage: startingPagePlace, groupName: groupnameplace })
    }

    select = () => {
      if(this.state.startingPage == 0) {
        console.log(this.state.startingPage)
            return (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign:'center'
              }}> 
              <Landing
              joinClick={this.handleJoinClick}></Landing>
              </div>
            )
            } else if (this.state.startingPage == 1) {
              console.log(this.state.startingPage)
              return (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}>
                <Calendar groupname= {this.state.groupName}
                />
              </div>
              )
            } else if (this.state.startingPage == 2) {
              console.log(this.state.startingPage)
              return (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign:'center'
                }}> 
                <Create
                joinClick={this.handleJoinClick}></Create>
                </div>
              )
            }
    }

    render(
    ) { 
      const number = this.props.number
      if(number === 1){
        return (
        <div>
        <Home></Home>
        </div>
        )
      }else if (number === 2) {
        return (
          <div>
        <div
        style={{textAlign: 'center',
        marginTop: 50,
        marginBottom: 50
        }}
        >
       <h1>
           Swift Scheduler
       </h1>
       </div>
       <div>
          {
          this.select()
          }
        </div>
  
          </div>
        )
      }else if (number === 3) {
        return ( 
        <div>
        <OneTower></OneTower>
        </div>
     )
    }else if (number === 4) {
      return ( 
          <div>
          <Impressum></Impressum>
          </div>
       )
      }else if (number === 5) {
        return ( 
            <div>
            <About></About>
            </div>
         )
      }
    }
}
 
export default PickPage;