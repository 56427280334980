import React from 'react';

const Test = (props) => {
    const test = props.test

        return (
            <div>
                {test}
            </div>
          );
 
}
 
export default Test;