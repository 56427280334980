import React, { Component } from 'react';

class About extends Component {
    state = {  }
    render() { 
        return (  
            <h1
            style={{textAlign: 'center',
            marginTop: 50,
            marginBottom: 50
            }}>
                About</h1>
        );
    }
}
 
export default About;