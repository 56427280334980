import React, { Component, useState} from 'react';
import axios from 'axios'

const Landing = (props)  =>  {   
    const [groupName, onChangeGroupName] = React.useState(null);
    const [groupPassword, onChangeGroupPassword] = React.useState(null);
    function setGroupName (data){
        onChangeGroupName(data.target.value)
    }
    function setGroupPassword (data){
        onChangeGroupPassword(data.target.value)
    }
    function clickJoin () {
        axios({
            method: 'POST',
            url: 'https://www.ag-solutions-group.com/swift_get_group.php?',
            data: {groupp: groupName, password: groupPassword}
        })
        .then(result => {
            console.log(result.data);
            props.joinClick([1, groupName]);
            })
        .catch(error => {
                console.log(error.data)
           })
    }

    return (    
        <div>
            <div>
                <h2>{groupName}</h2>
            <input
            onChange={setGroupName}
            placeholder="group name"></input>
            </div>
            <div>
            <input
            onChange={setGroupPassword}
            placeholder="group password"></input>
            </div>
           <div>
        <button className="btn btn-primary btn-lg" style={{margin: 5, width: 200}}
        onClick={clickJoin}
        >
            Join</button>
        </div>
        <h3 style={{margin: 5}}>OR</h3>
        <div>
        <button className="btn btn-primary btn-lg" style={{margin: 5, width: 200, textAlign:'center'}}
        onClick={ () => props.joinClick([2, ''])}
        >Create</button>
        </div>
        </div>
        )
}



export default Landing;

/*
const [name, onChangeName] = React.useState(null);
 function setName (data){
        onChangeName(data.target.value)
    }

 <div>
            <input 
            onChange={setName}
            placeholder="your name"></input>
            </div>
*/

